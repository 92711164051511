<template>
    <div>
        <b-button type="button" variant="info" class="ml-3" @click="showRepaymentSchedules()">Эргэн төлөлтийн хуваарь харах</b-button>

        <b-modal ref="my-modal" title="" size="xl">
                <template v-slot:modal-header>
                    <h4 class="mb-0">Зээлийн хуваарь</h4>
                    <b-button
                            variant="primary"
                            class="float-right"
                            size="sm"
                            @click="print()"
                        >
                            Хэвлэх
                        </b-button>
                </template>
                    <div class="page-container" id="printContainer">
                        <div class="page-header" style="text-align: right"> Барьцаат зээлийн гэрээ</div>
                        <div class="page-footer">
                            <div class="pull-right mr-4">Зээлдэгч: ___________________</div>
                            <div class=" ml-4">ББСБ-ыг төлөөлж:________________________</div>
                        </div>
                        <table>

                            <thead>
                                <tr>
                                    <td>
                                    <!--place holder for the fixed-position header-->
                                    <div class="page-header-space"></div>
                                    </td>
                                </tr>
                                </thead>

                                <tbody>
                                <tr>
                                    <td>
                                        <div class="page" >
            
                            <div class="clearfix">
                                <span class="pull-right contract_number">ЗГ-18/{{ contract_number }} тоот гэрээний Хавсралт №1</span>
                            </div>
                            <div class="h4 text-center mt-3 mb-4">ЗЭЭЛИЙН ЭРГЭН ТӨЛӨЛТИЙН ХУВААРЬ</div>
                            <ol>
                                <li>
                                    <p v-if="selectCustomer">
                                        Зээлдэгч {{ selectCustomer.lastname }} овогтэй {{ selectCustomer.firstname }} /{{ selectCustomer.registerNumber }}/ нь “Нөмөр кредит ББСБ” ХХК-аас авсан {{ amount | number }} төгрөгийн зээл, түүний хүүг доорх хуваарийн дагуу төлж барагдуулна.Үүнд:</p>
                                    <div class="text-center">
                                        <b-table 
                                            bordered
                                            :items="scheduleList"
                                            :fields="[
                                                { label: '№', key: 'index', class: 'text-center'},
                                                { label: 'Төлбөр хийх огноо', key: 'paymentDate', class: 'text-center'},
                                                { label: 'Үндсэн зээлээс төлөх дүн', key: 'mainAmount', class: 'text-right'},
                                                { label: 'Хүүгийн төлбөрийн дүн', key: 'interestAmount', class: 'text-right'},
                                                { label: 'Хойшлуулсан хүүний дүн', key: 'stopInterestAmount', class: 'text-right'},
                                                { label: 'Нийт төлбөрийн дүн', key: 'totalAmount', class: 'text-right'},
                                                { label: 'Үндсэн зээлийн үлдэгдэл', key: 'balance', class: 'text-right'},
                                            ]"
                                        >
                                            <template v-slot:cell(index)="data">
                                                <span v-if="(data.index < scheduleList.length-1)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.index + 1 }}</span>
                                            </template>
                                            <template v-slot:cell(paymentDate)="data">
                                                <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.paymentDate }}</span>
                                                <span v-else>{{ date }}</span>
                                            </template>
                                            <template v-slot:cell(interestAmount)="data">
                                                <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.interestAmount | currecryZero }}</span>
                                            </template>
                                            <template v-slot:cell(stopInterestAmount)="data">
                                                <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.stopInterestAmount | currecryZero }}</span>
                                            </template>
                                            <template v-slot:cell(mainAmount)="data">
                                                <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.mainAmount | currecryZero }}</span>
                                            </template>
                                            <template v-slot:cell(totalAmount)="data">
                                                <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.totalAmount | currecryZero }}</span>
                                            </template>
                                            <template v-slot:cell(balance)="data">
                                                <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.balance | currecryZero }}</span>
                                            </template>
                                            <template v-slot:cell(fee)="data">
                                                <span v-if="(data.index > 0)" :class="{'font-weight-bold': scheduleList.length==data.index+1}">{{ data.item.fee | currecryZero }}</span>
                                            </template>
                                        </b-table>
                                        <table>
                          
                        </table>
                                    </div>
                                    
                                </li>
                                <li>
                                    <p>“Нөмөр Кредит ББСБ” ХХК-ийн Хаан банк дахь 5434077338, 5034992840 тоот данс болон Худалдаа хөгжлийн банк дахь 460029584 тоот данс, Голомт банк дахь 8125102523 дансуудаас сонгон эргэн төлөлтийг төлнө</p>
                                </li>
                            </ol>
                            <div class="one-page">
                                <div class="h4 text-center">ЗЭЭЛИЙН ЭРГЭН ТӨЛӨЛТИЙН ХУВААРИЙГ БАТАЛГААЖУУЛСАН:</div>
                            <div class="ml-4 ">
                                <b-row class="mt-5">
                                    <b-col>
                                        <div class="h4">ЗЭЭЛДҮҮЛЭГЧИЙГ ТӨЛӨӨЛЖ:</div>
                                    </b-col>
                                    <b-col>
                                        <div class="h4">ЗЭЭЛДЭГЧ:</div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <p>___________________________</p>
                                        Нэр: Б.Одхүү<br />Албан тушаал: “Нөмөр Кредит”
                            ББСБ-ын ээлийн ахлах эдийн засагч<br />Утас:
                            75111133
                                    </b-col>
                                    <b-col v-if="selectCustomer">
                                        <p>___________________________</p>
                                        <p>
                                            Нэр: {{ selectCustomer.lastname }} {{  selectCustomer.firstname}} <br>
                                            РД: {{ selectCustomer.registerNumber }} <br>
                                            Утас: {{ selectCustomer.phoneNumber }} <br>
                                            Хаяг: {{ selectCustomer.address }} 
                                        </p>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-3">
                                    <b-col>
                                        <h4 class="h4">&nbsp;</h4>
                                        <p class="mt-4">___________________________</p>
                                        <p>Нэр: {{ this.$store.getters.nicename }}<br>Албан тушаал: “Нөмөр Кредит” ББСБ-ын Зээлийн ажилтан<br>Утас: {{ this.$store.getters.phone }}</p>
                                    </b-col>
                                    <b-col v-if="coCustomer">
                                        <h4 class="h4">ХАМТРАН ЗЭЭЛДЭГЧ:</h4>
                                        <p>___________________________</p>
                                        <p>
                                            Нэр: {{ coCustomer.lastname }} {{  coCustomer.firstname}} <br>
                                            РД: {{ coCustomer.registerNumber }} <br>
                                            Утас: {{ coCustomer.phoneNumber }} <br>
                                            Хаяг: {{ coCustomer.address }} 
                                        </p>
                                    </b-col>
                                </b-row>
                            </div>
                            </div>
                            
                        </div>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
            <tr>
                <td>
                <!--place holder for the fixed-position footer-->
                <div class="page-footer-space"></div>
                </td>
            </tr>
            </tfoot>
                        </table>
                        
                    </div>
                    <template v-slot:modal-footer>
                        <div class="w-100">
                        <b-button
                            variant="primary"
                            size="sm"
                            class="float-right"
                            @click="print()"
                        >
                            Хэвлэх
                        </b-button>
                        </div>
                    </template>
                    
            </b-modal>

    </div>
</template>

<script>

import moment from 'moment'

export default {
    name: 'new-loan',
    data: function () {
        return {
            isLoading: false,
            scheduleList: []
        }
    },
    props: {
        selectCustomer: { type: [Array, Object], default: () => [] },
        coCustomer: { type: [Array, Object], default: () => [] },
        contract_number: { type: String, default: '' },
        repaymentDay: { type: Number, default: null },
        amount: { type: Number, default: 0 },
        fee: { type: Number, default: 0 },
        interest: { type: Number, default: 0 },
        term: { type: Number, default: 1 },
        date: { type: String, default: '' },
        noInterestTerm: { type: Number, default: null },
        stop_date: { type: String, default: '' },
        stop_amount: { type: Number, default: 0 },
        stop_term: { type: Number, default: 0 },
        stop_type: { type: String, default: 'INTEREST_FIRST' },
    },
    methods: {
        showRepaymentSchedules: function() {    
            if(this.amount > 0) {
                this.generateRepaymentSchedule();
            } else {
                this.showToast('Анхаар', 'Зээлийн дүн оруулаагүй байна', 'warning')
            }
        },
        generateRepaymentSchedule: function() {

            let scheduleTotal = {
                paymentDate: 'Нийт',
                interestAmount: 0,
                mainAmount: 0,
                fee: 0,
                totalAmount: 0,
                savingMonthlyAmount: 0
            }

            this.$data.isLoading = true
            var fee = this.amount * this.fee / 100
            var monthPayment = 0
            var monthlyPayment = 0
            var k = 0
            var dayInterestPercent = this.interest * 12 / 365 / 100
            var daysInMonth = parseInt(moment(this.date).format('DD'));
            var loanDate = moment(moment(this.date).format('YYYY-MM-DD'))
            var lastDay = moment(loanDate.format('YYYY-MM-DD'))
            if(daysInMonth > this.repaymentDay) {
                lastDay = moment(lastDay.add(1, 'month').format('YYYY-MM-' + this.repaymentDay))
                var days = lastDay.diff(loanDate, 'days')
                k = (days >= 15) ? 1 : 2;
            } else {
                lastDay = moment(lastDay.format('YYYY-MM-' + this.repaymentDay))
                var days = lastDay.diff(loanDate, 'days')
                k = (days <= 15) ? 1 : 0;
            }

            let _date = this.date
            let _k = k
            if(this.stop_term > 0) {
                _date = this.stop_date
                _k = 1
            }
            monthPayment = this.getMonthlyPayment(this.amount, this.interest, this.term, _date, this.repaymentDay, _k)
            
            var balance = this.amount
            var firstDate  = moment(moment(_date).format('YYYY-MM-DD'))
            var days = 0
            var noInterstMonth = this.noInterestTerm ? this.noInterestTerm : 0
            var totalNoInterestAmount = 0

            this.$data.scheduleList = []
            var stop_term = this.stop_term
            if(this.stop_type == 'INTEREST_FIRST') {
                for(var i = 0; i < this.stop_term; i++) {
                    var payDay = moment(moment(this.date).add(i+k, 'month').format('YYYY-MM-' + this.repaymentDay))
                    days = payDay.diff(firstDate, 'days')
                    var interestAmount = this.stop_amount/stop_term
                    var mainAmount = 0
                    var totalAmount = interestAmount
                    var schedule = {
                        balance: balance,
                        paymentDate: payDay.format('YYYY-MM-DD'),
                        interestDay: days,
                        interestAmount: 0,
                        stopInterestAmount: interestAmount,
                        mainAmount: mainAmount,
                        fee: 0,
                        totalAmount: totalAmount
                    }
                    // schedule.totalAmount = totalAmount
                    // balance -= mainAmount

                    firstDate = payDay
                    this.$data.scheduleList.push(schedule)
                }
            }

            var divede_term = Math.min(this.term, 12)

            if(this.stop_type == 'INTEREST_LAST') {
                interestAmount += this.stop_amount/divede_term
            }

            for(var i = 0; i < this.term; i++) {
                var payDay = moment(moment(_date).add(i+_k, 'month').format('YYYY-MM-' + this.repaymentDay))
                days = payDay.diff(firstDate, 'days')

                var interestAmount = balance*dayInterestPercent*days
                var mainAmount = monthPayment-interestAmount
                var stopInterestAmount = 0
                
                
                if(this.stop_type == 'INTEREST_LAST') {
                    if(i < 12) {
                        stopInterestAmount = this.stop_amount/divede_term
                    } 
                }

                var totalAmount = interestAmount + mainAmount + stopInterestAmount;

                var schedule = {
                    balance: balance,
                    paymentDate: payDay.format('YYYY-MM-DD'),
                    interestDay: days,
                    interestAmount: interestAmount,
                    stopInterestAmount: stopInterestAmount,
                    mainAmount: mainAmount,
                    fee: 0,
                    totalAmount: totalAmount
                }

                schedule.totalAmount = totalAmount
                balance -= mainAmount

                firstDate = payDay

                this.$data.scheduleList.push(schedule)
                scheduleTotal.interestAmount += schedule.interestAmount
                scheduleTotal.mainAmount += schedule.mainAmount
                scheduleTotal.totalAmount += schedule.totalAmount
                scheduleTotal.fee += schedule.fee
                scheduleTotal.savingMonthlyAmount += schedule.savingMonthlyAmount
            }
            this.$data.scheduleList.push(scheduleTotal);
            this.$data.isLoading = false
            this.$refs['my-modal'].show()
        },
        getMonthlyPayment: function(loanAmount, interest, term, date, repaymentDay, k) {
            var monthlyPayment = 0
            var rate = 1
            var totalRate = 0
            var yearInterest = interest * 12/100
            var days = 0
            var firstDate = moment(moment(date).format('YYYY-MM-DD'))
            for(var i = 0; i < term; i++) {
                var payDay = moment(moment(date).add(i+k, 'month').format('YYYY-MM-' + repaymentDay))
                days = payDay.diff(firstDate, 'days')
                rate = this.decimalAdjust('round', (rate/(days*yearInterest/365 + 1)), -10)
                totalRate += rate

                firstDate = payDay
            }

            monthlyPayment = this.decimalAdjust('round', loanAmount/totalRate, -10)
            return monthlyPayment;
        },
        decimalAdjust: function(type, value, exp) {
            // Если степень не определена, либо равна нулю...
            if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
            }
            value = +value;
            exp = +exp;
            // Если значение не является числом, либо степень не является целым числом...
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
            }
            // Сдвиг разрядов
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            // Обратный сдвиг
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        },
        print() {
            this.$htmlToPaper('printContainer');
        }
    }
}
</script>